<template>

  <!-- 投诉提交页面 -->
  <div class="BGS">
    <Header></Header>
    <CSubmitBottom></CSubmitBottom>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import CSubmitBottom from "../components/CSubmitBottom.vue"
import Footer from "../components/Footer.vue"
export default {
  data() {
    return {};
  },
  components: {
    Header,
    CSubmitBottom,
    Footer
  },
};
</script>

<style scoped>
.BGS {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}
</style>